<template>
  <div class="modal-custom-show-result-mail-cdg">
    <div class="modal-custom-show-result-mail-cdg-content">
      <b class="title-envoi">Résultat d'envoi mail :</b>
      <button
        type="button"
        class="swal2-close"
        @click="$emit('closeResultSwal')"
      >
        ×
      </button>
      <div class="mt-3">
        <div
          class="mt-3"
          v-if="dataToShow && dataToShow.error && dataToShow.error.length"
        >
        <v-card outlined>
          <b class="title-section"> Liste des erreurs : </b>
              <div
                class="item-list  ml-3 mr-2 text-center mt-1"
                v-for="(error, i) in dataToShow.error"
                :key="'error-list' + i"
              >
                {{ error.message }}
              </div>
        </v-card>
        </div>
        <div
          class="mt-3"
          v-if="dataToShow && dataToShow.success && dataToShow.success.length"
        >
        <v-card outlined>
          <b class="title-section">
                Liste des succès :
              </b>
              <div
                v-for="(succes, iS) in dataToShow.success"
                class="item-list ml-3 text-center mt-1"
                :key="'succes-list' + iS"
              >
                {{ succes.message }}
              </div>
        </v-card>
        </div>
      </div>

      <!-- <div class="swal2-icon">
        <span class="swal2-x-mark">
          <span class="swal2-x-mark-line-left"></span>
          <span class="swal2-x-mark-line-right"></span>
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataToShow: { required: true }
  },
};
</script>

<style lang="scss">
.title-envoi {
  color: #704ad1;
  text-transform: uppercase;
  font-weight: bold;
}
.modal-custom-show-result-mail-cdg {
  background: rgba(0, 0, 0, 0.4);
  align-items: center;
  display: flex;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  .modal-custom-show-result-mail-cdg-content {
    background: rgb(245, 245, 252);
    display: grid;
    margin: auto;
    animation: swal2-show 0.3s;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 32em;
    max-width: 100%;
    padding: 1.25em;
    border: none;
    border-radius: 0.3125em;
    font-family: inherit;
    font-size: 1rem;
    .swal2-close {
      display: flex;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    justify-content: center;
    width: 1.2em;
    height: 1.2em;
    padding: 0;
    overflow: hidden;
    transition: color 0.1s ease-out;
    border: none;
    border-radius: 0;
    outline: initial;
    background: 0 0;
    color: #ccc;
    font-family: serif;
    font-size: 1.5em;
    line-height: 1.2;
    text-transform: none;
    border: 1px solid #704ad1;
    border-radius: 44px;
    margin: 4px;
    color: #704ad1;
    }
    .swal2-icon {
      display: flex;
      border-color: #f27474;
      position: relative;
      box-sizing: content-box;
      justify-content: center;
      width: 5em;
      height: 5em;
      margin: 1.25em auto 1.875em;
      border: 0.25em solid transparent;
      border-radius: 50%;
      font-family: inherit;
      line-height: 5em;
      cursor: default;
    }
    .swal2-x-mark {
      position: relative;
      flex-grow: 1;
      .swal2-x-mark-line-left {
        left: 1.0625em;
        transform: rotate(45deg);
        display: block;
        position: absolute;
        top: 2.3125em;
        width: 2.9375em;
        height: 0.3125em;
        border-radius: 0.125em;
        background-color: #f27474;
      }
      .swal2-x-mark-line-right {
        right: 1em;
        transform: rotate(-45deg);
        display: block;
        position: absolute;
        top: 2.3125em;
        width: 2.9375em;
        height: 0.3125em;
        border-radius: 0.125em;
        background-color: #f27474;
      }
    }
  }
  .item-list {
    text-align: initial;
    font-size: 11px;
  }
  .title-list {
    margin-top: 8px;
    margin-bottom: 2px;
    text-align: initial;
    font-size: 14px;
    font-weight: 700;
  }
  .title-section {
    color: #704ad1;
    text-transform: uppercase;
  }
}
</style>
